import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import { Col, Container, Row } from "react-bootstrap"

const siteMap = {
  items: [
    // {label: "Leistungen", url: "/#leistungen"},
    {label: "Makler", url: "/#makler"},
    {label: "Referenzen", url: "/#referenzen"},
    {label: "Immobilie bewerten", url: "/#start", prominent: true},
  ]
}

const IndexPage = () => {
  return (
    <Layout siteMap={siteMap}>
      <SEO title="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
      <Container className="mt-5 pt-5">
        <Row>
          <Col md="12" className="mt-5 pt-5">
            <h1>Impressum</h1>
            <p>Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG</p>
            <p>Poststr. 11, 21227 Bendestorf<br/>
              Telefon: 04183-93 60 00<br/>
              Telefax: 04183-77 50 39<br/>
              E-Mail: info@immobilienmakler-und-hausverwaltung.de</p><p>Amtsgericht Tostedt HRA 202998<br/>
              Steuernummer 15/205/1822<br/>
              USt. Ident-Nr. DE300672031<br/>
              Geschäftsführer: Jan Patrick Schwolle</p>
              <p>Gesamthaftung: Schwolle Beteiligungsgesellschaft mbH<br/>
              Amtsgericht Tostedt HRA 202200</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default IndexPage
